import React, { useId } from 'react';

import defaultStyles from './StarRate.module.css';

export interface StarRateProps {
    rate: number;
    color: string;
    themeStyles?: typeof defaultStyles;
}

export function StarRate({ rate, color, themeStyles }: StarRateProps): JSX.Element {
    const clipPathId = useId();
    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce((accumulator, className) => Object.assign({}, accumulator, { [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' ') }), {});
    } else {
        styles = defaultStyles;
    }
    return (
        <>
            <div
                aria-hidden
                className={styles.clip}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 228.13 38.31"
                >
                    <defs>
                        <clipPath
                            id={clipPathId}
                            transform="scale(0.5)"
                        >
                            <path d="m19.17,33.07c.57-.26,1.22-.26,1.78,0l9.17,4.82c1.95,1.02,3.23.09,2.86-2.08l-1.75-10.21c-.07-.62.13-1.24.55-1.7l7.42-7.23c1.58-1.54,1.09-3.05-1.09-3.36l-10.25-1.49c-.61-.12-1.14-.51-1.44-1.05L21.84,1.48c-.98-1.98-2.56-1.98-3.53,0l-4.59,9.29c-.3.54-.83.93-1.44,1.05l-10.26,1.49c-2.18.32-2.67,1.83-1.09,3.36l7.41,7.23c.43.46.63,1.08.56,1.7l-1.75,10.21c-.37,2.18.91,3.11,2.86,2.08l9.17-4.82Z" />
                            <path d="m66.17,33.07c.57-.26,1.22-.26,1.78,0l9.17,4.82c1.95,1.02,3.23.09,2.86-2.08l-1.75-10.21c-.07-.62.13-1.24.55-1.7l7.42-7.23c1.58-1.54,1.09-3.05-1.09-3.36l-10.25-1.49c-.61-.12-1.14-.51-1.44-1.05l-4.59-9.29c-.98-1.98-2.56-1.98-3.53,0l-4.59,9.29c-.3.54-.83.93-1.44,1.05l-10.26,1.49c-2.18.32-2.67,1.83-1.09,3.36l7.41,7.23c.43.46.63,1.08.56,1.7l-1.75,10.21c-.37,2.18.91,3.11,2.86,2.08l9.17-4.82Z" />
                            <path d="m113.17,33.07c.57-.26,1.22-.26,1.78,0l9.17,4.82c1.95,1.02,3.23.09,2.86-2.08l-1.75-10.21c-.07-.62.13-1.24.55-1.7l7.42-7.23c1.58-1.54,1.09-3.05-1.09-3.36l-10.25-1.49c-.61-.12-1.14-.51-1.44-1.05l-4.59-9.29c-.98-1.98-2.56-1.98-3.53,0l-4.59,9.29c-.3.54-.83.93-1.44,1.05l-10.26,1.49c-2.18.32-2.67,1.83-1.09,3.36l7.41,7.23c.43.46.63,1.08.56,1.7l-1.75,10.21c-.37,2.18.91,3.11,2.86,2.08l9.17-4.82Z" />
                            <path d="m160.17,33.07c.57-.26,1.22-.26,1.78,0l9.17,4.82c1.95,1.02,3.23.09,2.86-2.08l-1.75-10.21c-.07-.62.13-1.24.55-1.7l7.42-7.23c1.58-1.54,1.09-3.05-1.09-3.36l-10.25-1.49c-.61-.12-1.14-.51-1.44-1.05l-4.59-9.29c-.98-1.98-2.56-1.98-3.53,0l-4.59,9.29c-.3.54-.83.93-1.44,1.05l-10.26,1.49c-2.18.32-2.67,1.83-1.09,3.36l7.41,7.23c.43.46.63,1.08.56,1.7l-1.75,10.21c-.37,2.18.91,3.11,2.86,2.08l9.17-4.82Z" />
                            <path d="m207.17,33.07c.57-.26,1.22-.26,1.78,0l9.17,4.82c1.95,1.02,3.23.09,2.86-2.08l-1.75-10.21c-.07-.62.13-1.24.55-1.7l7.42-7.23c1.58-1.54,1.09-3.05-1.09-3.36l-10.25-1.49c-.61-.12-1.14-.51-1.44-1.05l-4.59-9.29c-.98-1.98-2.56-1.98-3.53,0l-4.59,9.29c-.3.54-.83.93-1.44,1.05l-10.26,1.49c-2.18.32-2.67,1.83-1.09,3.36l7.41,7.23c.43.46.63,1.08.56,1.7l-1.75,10.21c-.37,2.18.91,3.11,2.86,2.08l9.17-4.82Z" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div
                className={styles.rate}
                style={{ clipPath: `url(#${clipPathId})`, '--rate': rate, '--color': color } as React.CSSProperties}
            >
                <div className={styles.back}></div>
                <div className={styles.front}></div>
            </div>
        </>
    );
}

export default StarRate;
