import React, { FunctionComponent, cloneElement } from 'react';

import styles from './Awards.module.css';
import SVGaw1 from './assets/aw1.svg';
import SVGaw2 from './assets/aw2.svg';
import SVGaw3 from './assets/aw3.svg';
import SVGaw4 from './assets/aw4.svg';
import SVGaw5 from './assets/aw5.svg';
import SVGaw6 from './assets/aw6.svg';
import SVGaw7 from './assets/aw7.svg';
import SVGaw8 from './assets/aw8.svg';
import SVGaw9 from './assets/aw9.svg';
import SVGaw10 from './assets/aw10.svg';

interface AwardsProps {
    maxWidthAwards?: string;
}

const Awards = ({ maxWidthAwards }: AwardsProps) => {
    return (
        <div
            className={styles.awards}
            style={{ maxWidth: maxWidthAwards }}
        >
            <figure>
                <img
                    width="83"
                    height="76"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw1.src}
                />
            </figure>
            <figure>
                <img
                    width="102"
                    height="80"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw2.src}
                />
            </figure>
            <figure>
                <img
                    width="75"
                    height="80"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw3.src}
                />
            </figure>
            <figure>
                <img
                    width="75"
                    height="80"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw4.src}
                />
            </figure>
            <figure>
                <img
                    width="66"
                    height="74"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw5.src}
                />
            </figure>
            <figure>
                <img
                    width="66"
                    height="86"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw6.src}
                />
            </figure>
            <figure>
                <img
                    width="78"
                    height="78"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw7.src}
                />
            </figure>
            <figure>
                <img
                    width="78"
                    height="78"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw8.src}
                />
            </figure>
            <figure>
                <img
                    width="71"
                    height="78"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw9.src}
                />
            </figure>
            <figure>
                <img
                    width="71"
                    height="78"
                    decoding="async"
                    loading="lazy"
                    alt=""
                    src={SVGaw10.src}
                />
            </figure>
        </div>
    );
};

export default Awards;
